import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { Table } from 'antd';
import { getMedidataSiteOrPIAction, getMedidataSiteOrPIMoreAction } from './logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';

const MedidataMetrics = () => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(1);
  const MedidataSiteResults = useSelector((store) => store.getMedidataSiteOrPIResults);
  const deepDiveData = useSelector((state) => state.deepDive);
  const [data, setData] = useState([]);
  const [ctmsDataForSite, setCtmsDataForSite] = useState([])

  const site_quality_metrics_cols = [
    {
      title: 'Therapy Area',
      dataIndex: 'Therapeutic_area',
      key: 'Therapeutic_area',
    },
    {
      title: 'Indication',
      dataIndex: 'Indications',
      key: 'Indications',
    },
    {
      title: 'Overall Data Lag',
      dataIndex: 'overall_data_lag',
      key: 'overall_data_lag',
      render: (d) => {
        if (d >= 0) {
          return <div>{d}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Overall Data Correction Rate',
      dataIndex: 'overall_data_correction_rate',
      key: 'overall_data_correction_rate',
      render: (d) => {
        if (d >= 0) {
          return <div>{d}</div>
        }
        return <div> - </div>
      },
    },
  ];

  const ctms_columns = [
    {
      title: 'Site Protocol Deviation Factor',
      dataIndex: 'site_protocol_deviation_factor_average',
      key: 'site_protocol_deviation_factor_average',
      render: (d) => {
        if (d === null) {
          return <div>-</div>
        }
        return <div> {d.toFixed(3)} </div>
      },
    },
    {
      title: 'Site Retention Factor',
      dataIndex: 'site_retention_factor_average',
      key: 'site_retention_factor_average',
      render: (d) => {
        if (d === null) {
          return <div>-</div>
        }
        return <div> {d.toFixed(3)} </div>
      },
    },
    {
      title: 'Screen Failure Factor',
      dataIndex: 'screen_failure_factor',
      key: 'screen_failure_factor',
      render: (d) => {
        if (d === null) {
          return <div>-</div>
        }
        return <div> {d.toFixed(3)} </div>
      },
    },
  ]

  useEffect(() => {
    dispatch(
      getMedidataSiteOrPIAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
      }),
    );
  }, []);

  useEffect(() => {
    if (deepDiveData.flag) {
      if (Object.keys(deepDiveData?.data).length) {
        const ctmsDataValue = [{
          site_protocol_deviation_factor_average: deepDiveData?.data?.site_protocol_deviation_factor_average,
          site_retention_factor_average: deepDiveData?.data?.site_retention_factor_average,
          screen_failure_factor: deepDiveData?.data?.screen_failure_factor,
        }]
        setCtmsDataForSite(ctmsDataValue)
      }
    }
  }, [JSON.stringify(deepDiveData)]);

  useEffect(() => {
    if (MedidataSiteResults.flag) {
      setData(MedidataSiteResults?.data?.data?.map((itm) => ({ Therapeutic_area: itm.Therapeutic_area, Indications: itm.Indications, ...itm?.site_quality_metrics })))
    }
  }, [MedidataSiteResults]);

  const loadMoreRow = () => {
    setFrom(from + 1)
    dispatch(getMedidataSiteOrPIMoreAction({
      id: parse(location.search).id,
      dataset: 'centers',
      from: from + 1,
      size: 10,
    }))
  }

  const renderActiveTabContent = () => {
    return (
      <div className="CardList">
        <div className="site-medidata-header-content">Medidata Metrics :</div>
        <LazyLoadDiv
          className="card-list scrollbar"
          id="pi-list-in-search"
          total={MedidataSiteResults?.data?.total}
          currentTotal={(MedidataSiteResults?.data?.data || []).length}
          loadMoreRows={() => loadMoreRow()}
          height="calc(100vh - 447px)"
        >
          <Table columns={site_quality_metrics_cols} pagination={false} dataSource={data} />
        </LazyLoadDiv>
      </div>
    );
  };

  const renderTrialMetricesContent = () => {
    return (
      <div className="CardList">
        <div className="site-medidata-header-content">CTMS Metrics :</div>
        <div className="card-list scrollbar">
          <Table columns={ctms_columns} pagination={false} dataSource={ctmsDataForSite} />
        </div>
      </div>
    );
  }

  return (
    <Loader loading={MedidataSiteResults.loading}>
      <div className="qualitymatrices-tabledata">
        <div>
          <div className="qualitymatrices-tabledata-card card">{renderTrialMetricesContent()}</div>
          <div className="qualitymatrices-tabledata-card card">{renderActiveTabContent()}</div>
        </div>
      </div>
    </Loader>
  )
}

export default MedidataMetrics
